'use client'

import NextImage from 'next/image'
import { Text } from '@/components/ui/text'
import { useHero, useHeroProps } from './use-hero'
import { Button, ButtonGroup } from '@/components/ui/button'
import { ArtDirection } from '@/components/ui/art-direction'
import { Video } from '@/components/ui/video'
import HeroHighlight from './hero.highlight'

export type HeroProps = useHeroProps

const Hero = (props: HeroProps) => {
  const {
    className,
    sectionName,
    buttonAlign,
    isArtistPage,
    isVideoBackground,
    highlightType,
    data: {
      theme,
      align,
      preTitle,
      title,
      description,
      titleButtons,
      buttons,
      titleCta,
      cta,
      imageHighlight,
      background,
      backgroundMobile,
      brands
    },
    styles
  } = useHero(props)

  return (
    <section
      data-component-name={sectionName}
      className={styles.base({ className })}
    >
      <div className={styles.wrapper()}>
        <div>
          {brands.length > 0 && (
            <div className={styles.logos()}>
              {brands.map(({ id, title, brand, url }) => (
                <div key={`brand-${id}`}>
                  {url ? (
                    <Button
                      sectionName={`${sectionName}_${title}`}
                      title={title}
                      href={url}
                      image={brand}
                    />
                  ) : (
                    <NextImage
                      src={brand.url}
                      alt={title}
                      width={brand.width}
                      height={brand.height}
                      className={styles.brand()}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {!!preTitle && (
            <Text
              className={styles.preTitle()}
              theme={theme}
              weight="semibold"
              size="tag"
              uppercase
            >
              {preTitle}
            </Text>
          )}
          {!!title && (
            <Text
              theme={theme}
              tag="h1"
              size={isArtistPage ? 'h4' : 'h3'}
              weight="medium"
              className={styles.title()}
            >
              {title}
            </Text>
          )}
          {!!description && (
            <Text
              theme={theme}
              size="description"
              weight="medium"
              className={styles.description()}
            >
              {description}
            </Text>
          )}
          <ButtonGroup
            theme={theme}
            sectionName={sectionName}
            title={titleButtons}
            buttons={buttons}
            className={styles.buttonGroup()}
            align={buttonAlign}
            hideOnMobile={cta.length >= 2}
          />
          <ButtonGroup
            theme={theme}
            sectionName={sectionName}
            className={styles.buttonGroup()}
            title={titleCta}
            buttons={cta}
            align={buttonAlign}
            hideStoresOnDesktop={false}
          />
        </div>
        <HeroHighlight
          align={align}
          highlightType={highlightType}
          imageHighlight={imageHighlight}
        />
      </div>
      <ArtDirection
        fill
        priority
        hiddenOn="md"
        className={styles.image()}
        containerClassName={styles.figure()}
        desktop={{
          src: background?.url,
          alt: background?.alternativeText
        }}
        mobile={{
          src: highlightType !== 'video' ? backgroundMobile?.url : undefined,
          alt:
            highlightType !== 'video'
              ? backgroundMobile?.alternativeText
              : undefined
        }}
      />
      {isVideoBackground && background && (
        <div className={styles.videoWrapper()}>
          <Video
            containerClassName={styles.videoContainer()}
            className={styles.video()}
            src={background?.url}
            mime={background?.mime}
          />
        </div>
      )}
    </section>
  )
}

export default Hero
